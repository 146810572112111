import $ from "jquery";

$(document).on('ready', function(){
    setTimeout(function() {
        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'get_user_status'
            },
            success: function(response) {
                var textOnly = $('.et_b_header-account a.et-toggle').text().trim();
                if (response.status === 'logged_in' && textOnly === 'Zaloguj / Rejestracja') {
                    var $anchor = $('<a>', {
                        href: '/moje-konto/',
                        class: 'flex full-width align-items-center justify-content-center mob-justify-content-start'
                    });
    
                    var $spanContainer = $('<span>', {
                        class: 'flex-inline justify-content-center align-items-center flex-wrap'
                    });
    
                    var $svgIcon = $('<span>', {
                        class: 'et_b-icon'
                    }).html('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M17.4 12.072c1.344-1.32 2.088-3.12 2.088-4.992 0-3.888-3.144-7.032-7.032-7.032s-7.056 3.144-7.056 7.032c0 1.872 0.744 3.672 2.088 4.992-3.792 1.896-6.312 6.216-6.312 10.92 0 0.576 0.456 1.032 1.032 1.032h20.52c0.576 0 1.032-0.456 1.032-1.032-0.048-4.704-2.568-9.024-6.36-10.92zM14.976 11.4l-0.096 0.024c-0.048 0.024-0.096 0.048-0.144 0.072l-0.024 0.024c-0.744 0.384-1.488 0.576-2.304 0.576-2.76 0-4.992-2.232-4.992-4.992s2.256-5.016 5.016-5.016c2.76 0 4.992 2.232 4.992 4.992 0 1.776-0.936 3.432-2.448 4.32zM9.456 13.44c0.936 0.456 1.944 0.672 2.976 0.672s2.040-0.216 2.976-0.672c3.336 1.104 5.832 4.56 6.192 8.52h-18.336c0.384-3.96 2.88-7.416 6.192-8.52z"></path></svg>');
    
                    var $labelSpan = $('<span>', {
                        class: 'et-element-label inline-block mob-hide',
                        text: response.username
                    });
    
                    $spanContainer.append($svgIcon, $labelSpan);
    
                    $anchor.append($spanContainer);
    
                    $anchor.on('click', function(event) {
                        event.preventDefault();
                        window.location.href = '/moje-konto/';
                    });
    
                    $('.et_b_header-account').first().html($anchor);
                    $('.mobile-header-wrapper .et_b_header-account a .et-element-label').text(response.username);
                }
            }
        });
    }, 500);
});
